import { GetTokenProps, TokenActions, TokenResponse } from './types'

type EmployerDetailProps = {
  id: GetTokenProps['data']['id']
  listingId: GetTokenProps['data']['listingId']
  shiftID: GetTokenProps['data']['shiftID']
  workerID: GetTokenProps['data']['workerID']
  userID: GetTokenProps['userData']['id']
}

type RedirectFlagProps = {
  blockedWorkers?: boolean
  flexAgent?: boolean
  flexAgentConversationId?: number
}
type TokenResponseProps = {
  res: TokenResponse
}

// E.g. 'https://portal.indeedflex.com/{path}?access_token={token}&impersonatorId={userId}'
function buildAccessTokenUrl(domain: string, token: string, userID: number, path: string): string {
  return `https:${domain}${path}?access_token=${token}&impersonatorId=${userID}`
}

export const createUrl = ({
  res,
  userID,
  actions,
  id,
  listingId,
  blockedWorkers,
  shiftID,
  workerID,
  flexAgent,
  flexAgentConversationId,
  target,
}: Pick<GetTokenProps, 'actions'> &
  EmployerDetailProps &
  RedirectFlagProps &
  TokenResponseProps & { target?: HTMLAnchorElement['target'] }) => {
  const url = res?.payload?.url
  if (!url) return null

  const { domain, token } = parsePortalUrl(url)
  const buildURL = buildAccessTokenUrl.bind(this, domain, token, userID)
  let redirectUrl = `${url}&impersonatorId=${userID}`

  if (listingId) {
    redirectUrl = buildURL(`day-schedule/${listingId}/shifts/${shiftID || 'first'}`)
  } else if (workerID) {
    redirectUrl = buildURL(`worker/${workerID}`)
  } else if (blockedWorkers) {
    redirectUrl = buildURL('settings/blocked-workers')
  } else if (flexAgentConversationId) {
    redirectUrl = buildURL(`o/flex-agent/${flexAgentConversationId}`)
  } else if (flexAgent) {
    redirectUrl = buildURL('o/flex-agent')
  }
  actions.saveEmployerToken({ id, url: redirectUrl })
  return target ? window.open(redirectUrl, target) : window.open(redirectUrl)
}

export const generateWorkerPortalUrl = ({
  actions,
  employerId,
  workerID,
}: {
  actions: TokenActions
  employerId: number
  workerID?: number
}): Promise<string | null> => {
  actions.setEmployerLoading({ id: employerId })
  return new Promise<TokenResponse>(resolve => actions.loginAsEmployer(employerId, resolve)).then(res => {
    const url = res?.payload?.url
    if (!url) return null

    const domainMatches = url.match(/\/\/(.+?)\.(.+?)\.(.+?)\//)
    const domain = domainMatches ? domainMatches[0] : ''
    const workerURL = `https:${domain}worker/${workerID}`
    return workerURL
  })
}

export const getTokenOpenLogin = ({
  data,
  userData,
  token,
  actions,
  employerId,
  blockedWorkers,
  flexAgent,
  flexAgentConversationId,
  target,
}: GetTokenProps & RedirectFlagProps & { target?: HTMLAnchorElement['target'] }): void | null => {
  const isApproved = !!data?.approved_at || false
  const listingId = data?.listingId
  const userID = Number(userData?.id)
  const shiftID = data?.shiftID
  const workerID = data?.workerID

  // Don't request a token if this employer isn't approved.
  if (!isApproved) {
    return null
  }

  const id = data?.id || employerId
  // We don't load if we don't have an ID or if we're already loading.
  if (id && !token?.loading) {
    actions.setEmployerLoading({ id })
    new Promise<TokenResponse>(resolve => actions.loginAsEmployer(id, resolve)).then(res =>
      createUrl({
        res,
        userID,
        actions,
        id,
        listingId,
        blockedWorkers,
        flexAgent,
        flexAgentConversationId,
        shiftID,
        workerID,
        target,
      }),
    )
  }
}

const parsePortalUrl = (url: string): { domain: string; token: string } => {
  const tokenMatches = url.match(/access_token=([^"]+)/)
  const domainMatches = url.match(/\/\/(.+?)\.(.+?)\.(.+?)\//)
  const token = tokenMatches ? tokenMatches[1] : '' // e.g. '884832203'
  const domain = domainMatches ? domainMatches[0] : '' // e.g. '//portal.indeedflex.co.uk/'
  return { domain, token }
}
