import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from '@indeed/ifl-components'
import { updateBooking, updateBookingChanges } from 'syft-acp-core/actions/bookings'

import './ListingShiftsBreaksDetails.css'
import { ButtonIcon } from 'syft-acp-atoms/Button'
import { DataDatetime } from 'syft-acp-core/components/EditableTable'

const reasonTextMap = {
  voluntary: 'Voluntarily skipped',
  involuntary: 'Requested to skip',
}

const ListingMealBreakDetails = ({
  shiftBookingId,
  disabled,
  timeZone,
  attr,
  isEditingMealBreak,
  onEditMealBreak,
}) => {
  const dispatch = useDispatch()
  const shiftBooking = useSelector(state => state?.listingShiftBookings?.entityMap[shiftBookingId])

  const updateBookingAction = val => {
    dispatch(updateBooking({ id: shiftBookingId, attr, val }))
    dispatch(updateBookingChanges({ id: shiftBookingId, attr, val }))
  }

  const handleTimeChange = (mealIndex, key) => value => {
    const mealDetailItem = shiftBooking.meal_details[mealIndex]?.meal || {}
    const newMealDetails = Object.assign([], shiftBooking.meal_details, {
      [mealIndex]: {
        meal: {
          ...mealDetailItem,
          [key]: value,
        },
      },
    })

    updateBookingAction(newMealDetails)
  }

  const handleEditSelectOnChange = mealIndex => event => {
    const value = event.target.value

    if (value) {
      const mealDetailItem = shiftBooking.meal_details[mealIndex]?.meal
      const newMealDetails = Object.assign([], shiftBooking.meal_details, {
        [mealIndex]: {
          ...(mealDetailItem
            ? {
                meal: {
                  id: mealDetailItem.id,
                  _destroy: true,
                },
              }
            : {}),
          violation: {
            reason: value,
          },
        },
      })

      updateBookingAction(newMealDetails)
    } else {
      const mealDetailItem = shiftBooking.meal_details[mealIndex]?.meal || {}
      const violoationDetails = shiftBooking.meal_details[mealIndex]?.violation || {}
      const newMealDetails = Object.assign([], shiftBooking.meal_details, {
        [mealIndex]: {
          meal: { ...mealDetailItem },
          violation: { ...violoationDetails, destroy: true },
        },
      })

      updateBookingAction(newMealDetails)
    }
  }

  const handleEdit = () => onEditMealBreak(true)
  const handleFinishEdit = () => onEditMealBreak(false)

  return (
    <Box sx={{ py: 1, px: 3, minWidth: '290px' }}>
      {isEditingMealBreak ? (
        <Flex sx={{ gap: 2 }}>
          <Flex sx={{ flex: '1 1 100%', flexDirection: 'column', gap: 2 }}>
            {shiftBooking.meal_details.map((mealDetail, index) => (
              <Box
                key={`meal-edit-${index}`}
                sx={{
                  pb: 2,
                  borderBottom: '1px solid #CCC',
                  '&:last-of-type': { borderBottom: 'none', pb: 0 },
                }}
              >
                <select
                  className="form-control"
                  onChange={handleEditSelectOnChange(index)}
                  value={mealDetail?.violation?.reason || ''}
                >
                  <option value="">Add meal start/end time</option>
                  <option value="voluntary">Voluntarily skipped meal</option>
                  <option value="involuntary">Requested to skip meal</option>
                </select>
              </Box>
            ))}
          </Flex>
          <Box>
            <ButtonIcon icon="check" kind="primary" aria-label="Finish editing" onClick={handleFinishEdit} />
          </Box>
        </Flex>
      ) : (
        <Flex sx={{ gap: 2 }}>
          <Flex sx={{ flex: '1 1 100%', flexDirection: 'column', gap: 2 }}>
            {shiftBooking?.meal_details?.map((mealDetail, index) => (
              <Flex
                key={`meal-${index}`}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  gap: 1,
                  pb: 2,
                  borderBottom: '1px solid #CCC',
                  '&:last-of-type': { borderBottom: 'none', pb: 0 },
                }}
              >
                {mealDetail.violation ? (
                  <Box
                    sx={{
                      backgroundColor: '#6C757D',
                      color: '#fff',
                      borderRadius: '4px',
                      padding: '5px 11px',
                      fontWeight: 'bold',
                      lineHeight: '24px',
                    }}
                  >
                    {reasonTextMap[mealDetail.violation?.reason.toLowerCase()]}
                  </Box>
                ) : (
                  <>
                    {mealDetail.meal && (
                      <>
                        <DataDatetime
                          value={mealDetail.meal?.start}
                          onChange={handleTimeChange(index, 'start')}
                          disabled={disabled}
                          parameters={{ timeZone }}
                          showTimezone={false}
                          editable
                          data={{}}
                        />
                        <DataDatetime
                          value={mealDetail.meal?.end}
                          onChange={handleTimeChange(index, 'end')}
                          disabled={disabled}
                          parameters={{ timeZone }}
                          showTimezone={false}
                          editable
                          data={{}}
                        />
                      </>
                    )}
                  </>
                )}
              </Flex>
            ))}
          </Flex>
          <Box>
            <ButtonIcon icon="pencil" aria-label="Edit" onClick={handleEdit} />
          </Box>
        </Flex>
      )}
    </Box>
  )
}

export default ListingMealBreakDetails
